<template>
  <v-row no-gutters class="flex-column">
    <v-card outlined>
      <v-card-text>
        <v-row no-gutters>
          <h4>
            Informasi Hadiah
            <v-btn icon @click="$refs.prize.reset()" class="ml-2">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </h4>
          <v-spacer></v-spacer>
          <v-btn depressed @click="handlePrizes('add')">
            <span>tambah</span>
          </v-btn>
        </v-row>
        <v-form ref="prize">
          <v-row v-for="(p, i) in form.data.prizes" :key="i">
            <v-col>
              <v-autocomplete
                label="Event"
                v-model="p.event_id"
                :rules="form.rules.event"
                :items="allEvents"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Judul"
                v-model="p.title"
                :rules="form.rules.title"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="Batas Pemenang"
                v-model.number="p.max_winners"
                :rules="form.rules.max_winners"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Deskripsi"
                v-model="p.description"
                :rules="form.rules.description"
              >
                <template
                  v-if="form.data.prizes.length > 1"
                  v-slot:append-outer
                >
                  <v-btn
                    icon
                    color="deep-orange accent-2"
                    @click="handlePrizes('remove', i)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          large
          @click="saveData"
          :loading="form.loading"
          :disabled="form.loading"
        >
          <span>simpan</span>
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  data() {
    return {
      form: {
        loading: false,
        data: {
          prizes: [
            {
              event_id: null,
              title: null,
              description: null,
              max_winners: null,
            },
          ],
        },
        rules: {
          title: [(v) => !!v || "Judul harus diisi"],
          start_date: [(v) => !!v || "Tanggal mulai harus diisi"],
          end_date: [(v) => !!v || "Tanggal selesai harus diisi"],
          participant_limit: [(v) => !!v || "Batas peserta harus diisi"],
          shipment_cost: [(v) => !!v || "Pilih gratis ongkir"],
          image: [
            (v) => !!v || "Pilih banner event",
            (value) =>
              !value ||
              value.size < 2000000 ||
              "Ukuran file banner harus kurang dari 2 MB!",
          ],
          event: [(v) => !!v || "Pilih event"],
          description: [(v) => !!v || "Deskripsi harus diisi"],
          max_winners: [(v) => !!v || "Batas pemenang harus diisi"],
        },
      },
      snackbar: {
        vm: false,
        color: null,
        icon: null,
        text: null,
      },
    };
  },
  methods: {
    ...mapActions([
      'fetchEvents',
      'addPrize',
    ]),
    handlePrizes(act, index = null) {
      switch (act) {
        case "add":
          this.form.data.prizes.push({
            event_id: null,
            title: null,
            description: null,
            max_winners: null,
          });
          break;
        case "remove":
          this.form.data.prizes.splice(index, 1);
          break;
      }
    },
    saveData(){
      let data = this.form.data.prizes
      let isValid = this.$refs.prize.validate()
      
      this.form.loading = true
      if(isValid){
        for(let item of data){
          this.addPrize(item)
        }
        this.showNotification('success')
        this.form.loading = false
      } else {
        this.showNotification('error')
        this.form.loading = false
      }
    },
    showNotification(status){
      this.snackbar.vm = true
      switch(status){
        case 'success':
          this.snackbar.color = 'success'
          this.snackbar.icon = 'mdi-check-circle'
          this.snackbar.text = 'Data berhasil disimpan'
          break
        case 'error':
          this.snackbar.color = 'error'
          this.snackbar.icon = 'mdi-close-circle'
          this.snackbar.text = 'Terjadi kesalahan'
          break
      }
    }
  },
  computed: {
    ...mapGetters([
      'allEvents',
    ]),
  },
  mounted() {
    this.fetchEvents()
  },
};
</script>